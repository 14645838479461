import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WizardPageWrapper from 'components/wizard/wizardPageWrapper'
import styled from 'styled-components'
import { LoadingOutlined } from '@ant-design/icons'
import { useGetSessionData, useLog, useStartTransaction } from 'hooks/api'
import { setWizardSessionToken, setWizardSessionRedirectUrl } from 'store/slices/app'
import { setCurrentStep } from 'store/slices/wizard'
import mixPanel from './services/mixpanel'
import { serviceProviderNonTrasaction, serviceProviderNonTrasactionRedirect } from 'utils/constants'

const WizardWrapper = styled.div`
  position: relative;
  width:100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.modalButtonTextColor} !important;
`

const LoadingSection = styled.div`
  margin-top: 5em;
  display: flex;
  justify-content: center;
`

const LoaderIcon = styled.div`
  color: ${({ theme }) => theme.colors.highlightPrimary};
  height: 56px;
  width: 56px;
  background: ${({ theme }) => theme.colors.loaderBackgound};
  border-radius: 50px;
  padding: 8px;
`

const Header = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-top: 15px;
  text-align: center;
`

const Subtitle = styled.div`
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
`

const Subtext = styled.div`
  text-align: center;
`

const BackButton = styled.div`
  // background: ${({ theme }) => theme.buttonColor && theme.buttonColor } ${({ theme }) => theme.buttonColor && `!important`};
  // background: linear-gradient(112.11deg, ${({ theme }) => theme.colors.filled} -18.69%, ${({ theme }) => theme.colors.highlightPrimary} 102.07%) ${({ theme }) => !theme.buttonColor && `!important`};
  background: ${({ theme }) => theme.name === 'darkMode' ? ((theme.darkModeButtonColor === '#zzzzzz' || !theme.darkModeButtonColor) ? theme.colors.filled : theme.darkModeButtonColor) : ((theme.buttonColor === '#zzzzzz' ||  !theme.buttonColor) ? theme.colors.filled : theme.buttonColor) } !important;
  color: ${({ theme }) => theme.colors.foregroundPrimary};
  text-align: center;
  padding: 15px 40px;
  min-height: 56px;
  width: 100%;
  position: absolute;
  bottom: 6.8em;
  border-radius: 5px;
  cursor: pointer;

  @media only screen and (max-width: 335px) {
    height: 60px;
  }

  width: 100%;
  font-size: 16px;
  transition: transform .4s;
  &:hover {
    transform: scale(${({ transform }) => transform});
    transform-origin: 50% 50%;
  }
`

const ClickButton = styled.div`
  cursor: pointer;
  display: inline-block;
  color: ${({ theme }) => theme.colors.antdInputBorder};

  &:hover {
    color: ${({ theme }) => theme.colors.antdHoverInputBorder};
  }
`

const Page = ({sessionQuery, buySellFlow, tabClosed, wizardSessionServiceProvider, openNewTab }) => {
  const dispatch = useDispatch()
  const redirectTime = 5
  const { widgetRedirectUrl, wizardSessionToken } = useSelector((state) => state.app)
  const { mutateAsync: postLog } = useLog()
  const [ remainSec, setRemainSec ] = useState(redirectTime)
  let timer

  useEffect(async () => {
    if (sessionQuery?.sessionData?.serviceProvider === 'BANXA') {
      const logger = {
        sessionTokn: wizardSessionToken,
        serviceProvider: sessionQuery?.sessionData?.serviceProvider,
        meldAccountId: sessionQuery?.accountId,
        meldCustomerId: sessionQuery?.customerId,
        message: 'Banxa: Loaded'
      }
      await postLog({ message: logger })
    }
  }, [sessionQuery])

  useEffect(() => {
    if (tabClosed && remainSec === redirectTime) {
      let remainTime = (remainSec - 1)
      timer = setInterval(() => {
        setRemainSec(remainTime--)
        if (remainTime < 0) {
          goBack()
        }
      }, 1000)
    }
  }, [tabClosed, remainSec])

  const goBack = async (isButtonClicked) => {
    if (isButtonClicked) {
      mixPanel({
        eventName: buySellFlow === 'BUY' ? 'buy_waiting_return' : 'sell_waiting_return',
        buySellFlow: buySellFlow
      })
    }
    if (sessionQuery?.sessionData?.serviceProvider === 'BANXA') {
      const logger = {
        sessionTokn: wizardSessionToken,
        serviceProvider: sessionQuery?.sessionData?.serviceProvider,
        meldAccountId: sessionQuery?.accountId,
        meldCustomerId: sessionQuery?.customerId,
        message: 'Banxa: Exit'
      }
      await postLog({ message: logger })
    }
    clearInterval(timer)
    setRemainSec(redirectTime)
    dispatch(setWizardSessionToken(''))
    dispatch(setWizardSessionRedirectUrl(''))
    return dispatch(setCurrentStep('wizard'))
  }

  const openTab = () => {
    openNewTab(widgetRedirectUrl)
  }

  return (
    <>
      <LoadingSection id="wizard-redirect-loading">
        { !tabClosed &&
          <LoaderIcon id="wizard-redirect-loader-section">
            <LoadingOutlined id="wizard-redirect-loader" style={{fontSize: '40px'}} />
          </LoaderIcon>
        }
      </LoadingSection>
      <Header id="wizard-redirect-header" style={{marginTop: tabClosed && '3.5em'}}>
        {
          tabClosed ? 'Processing' : `Continue with ${wizardSessionServiceProvider?.name}`
        }
      </Header>
      <Subtitle id="wizard-redirect-body-content">
        {
          tabClosed ?
            'If you submitted a transaction, please check your email for the latest status. If not, you can try again below.'
            : 'Complete your transaction in the other tab. This page will update once you are finished.'
        }
      </Subtitle>
      {
        widgetRedirectUrl !== '' && (
          <Subtext id="wizard-redirect-popup-instruction">
            {
              tabClosed ? '' :
                <>Please make sure that popups are enabled. If you don&#x27;t see a {wizardSessionServiceProvider?.name} window,
                <ClickButton id="wizard-redirect-popup-link" onClick={openTab}>click here</ClickButton> to open {wizardSessionServiceProvider?.name} in a new tab.</>
            }
          </Subtext>
        )
      }
      <div>
        <BackButton id="wizard-redirect-back-button" onClick={(e) => goBack(true)}>
          {
            !tabClosed ? 'Back to Quotes' :
              <>You will be redirected back to quotes in {remainSec}</>
          }
        </BackButton>
      </div>
    </>
  )
}

const Transaction = ({ sessionQuery, openNewTab, tabClosed, wizardSessionServiceProvider }) => {
  const dispatch = useDispatch()
  const { wizardSessionToken } = useSelector((state) => state.app)
  const { mutateAsync: postLog } = useLog()
  const config = {
    sessionToken: wizardSessionToken
  }

  useEffect(async () => {
    if (sessionQuery?.sessionData?.serviceProvider === 'BANXA') {
      const logger = {
        sessionTokn: wizardSessionToken,
        serviceProvider: sessionQuery?.sessionData?.serviceProvider,
        meldAccountId: sessionQuery?.accountId,
        meldCustomerId: sessionQuery?.customerId,
        message: 'Banxa: Transaction API called'
      }
      await postLog({ message: logger })
    }
  }, [sessionQuery])

  const { isFetching: isFetchingTransaction, data: transactionData } = useStartTransaction({
    sourceAmount: sessionQuery?.sessionData?.sourceAmount,
    sourceCurrencyCode: sessionQuery?.sessionData?.sourceCurrencyCode,
    paymentMethod: {
      type: sessionQuery?.sessionData?.paymentMethodType
    },
    serviceProviderDetails: {
      destinationCurrencyCode: sessionQuery?.sessionData?.destinationCurrencyCode,
      walletAddress: sessionQuery?.sessionData?.walletAddress,
      walletTag: sessionQuery?.sessionData?.walletTag
    },
    serviceProvider: sessionQuery?.sessionData?.serviceProvider,
  }, config)

  useEffect(() => {
    if (transactionData?.transaction?.serviceProviderDetails?.checkoutIframe) {
      dispatch(setWizardSessionRedirectUrl(transactionData.transaction.serviceProviderDetails.checkoutIframe))
      openNewTab(transactionData.transaction.serviceProviderDetails.checkoutIframe)
    }

    if (transactionData?.response?.status === 500) {
      sessionStorage.setItem('widgetError', 'Recheck your purchase amount and wallet address and try again.')
      return dispatch(setCurrentStep('wizard'))
    }
    if (transactionData?.response?.status === 400 && transactionData?.response?.data?.code === 'CRYPTO_INVALID_DESTINATION_WALLET') {
      sessionStorage.setItem('widgetError', 'Recheck your purchase amount and wallet address and try again.')
      return dispatch(setCurrentStep('wizard'))
    }
  }, [isFetchingTransaction, transactionData, setWizardSessionRedirectUrl])
  return (<Page sessionQuery={sessionQuery} buySellFlow={sessionQuery?.sessionData?.sessionType} openNewTab={openNewTab} tabClosed={tabClosed} wizardSessionServiceProvider={wizardSessionServiceProvider} />)
}

const WizardRedirect = () => {
  const dispatch = useDispatch()
  const wizardDrawerRef = useRef(null)
  const [ tabClosed, setTabClosed ] = useState(false)
  const { mutateAsync: postLog } = useLog()
  const { wizardSessionToken, token, wizardSessionServiceProvider } = useSelector((state) => state.app)
  const config = {
    sessionToken: wizardSessionToken
  }
  const { isFetched: hasFetchedSessionData, data: sessionQuery } = useGetSessionData(!!token, config)

  useEffect(async () => {
    if (sessionQuery?.sessionData?.serviceProvider === 'BANXA') {
      const logger = {
        sessionTokn: wizardSessionToken,
        serviceProvider: sessionQuery?.sessionData?.serviceProvider,
        meldAccountId: sessionQuery?.accountId,
        meldCustomerId: sessionQuery?.customerId,
        message: 'Banxa: Initialize'
      }
      await postLog({ message: logger })
    }
    if (sessionQuery?.sessionData?.serviceProviderUrl && (sessionQuery?.sessionData?.paymentMethodType === 'APPLE_PAY' || serviceProviderNonTrasactionRedirect.includes(wizardSessionServiceProvider?.serviceProvider))) {
      dispatch(setWizardSessionRedirectUrl(sessionQuery.sessionData.serviceProviderUrl))
      openNewTab(sessionQuery.sessionData.serviceProviderUrl)
    }
  }, [hasFetchedSessionData, sessionQuery, setWizardSessionRedirectUrl, wizardSessionServiceProvider?.serviceProvider])

  const openNewTab = (link) => {
    const windowPopup = window.open(link)
    let timer = setInterval(function() {
      if(windowPopup?.closed) {
        clearInterval(timer)
        setTabClosed(true)
      }
    }, 500)
  }

  function renderContents() {
    return (serviceProviderNonTrasaction.includes(wizardSessionServiceProvider?.serviceProvider)) ?
      <Page
        sessionQuery={sessionQuery}
        buySellFlow={sessionQuery?.sessionData?.sessionType}
        openNewTab={openNewTab}
        tabClosed={tabClosed}
        wizardSessionServiceProvider={wizardSessionServiceProvider}
      /> :
      <Transaction sessionQuery={sessionQuery} openNewTab={openNewTab} tabClosed={tabClosed} wizardSessionServiceProvider={wizardSessionServiceProvider}/>
  }

  return (
    <>
      {
        sessionQuery &&
        <WizardPageWrapper showBuySellToggle={false} wizardDrawerRef={wizardDrawerRef}>
          <WizardWrapper id="wizard-redirect">{ renderContents() }</WizardWrapper>
        </WizardPageWrapper>
      }
    </>
  )
}

export default WizardRedirect
