import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
  displayPage: 'wizard', // in-app routing
  token: '',
  accountId: null,
  queryParams: {},
  sessionData: {},
  globalAlerts: [],
  wizardSessionToken: '',
  wizardSessionServiceProvider: {},
  widgetRedirectUrl: '',
  widgetSessionDirectUrl: '',
}

export const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDisplayPage: (state, action) => {
      state.displayPage = action.payload
      state.globalAlerts = state.globalAlerts.filter((a) => a.persist)
    },
    setQueryParams: (state, action) => {
      state.queryParams = action.payload
    },
    setToken: (state, action) => {
      sessionStorage.setItem('token', action.payload)
      state.token = action.payload
    },
    setWizardSessionToken: (state, action) => {
      state.wizardSessionToken = action.payload
    },
    setWizardSessionDirectUrl: (state, action) => {
      state.widgetSessionDirectUrl = action.payload
    },
    setWizardSessionServiceProvider: (state, action) => {
      state.wizardSessionServiceProvider = action.payload
    },
    setWizardSessionRedirectUrl: (state, action) => {
      state.widgetRedirectUrl = action.payload
    },
    setAccountId: (state, action) => {
      state.accountId = action.payload
    },
    setSessionData: (state, action) => {
      state.sessionData = action.payload
    },
    updateSessionData: (state, action) => {
      state.sessionData = _.merge({}, state.sessionData, action.payload)
    },
    showGlobalAlert: (state, action) => {
      if (!action.payload) {
        return
      }
      state.globalAlerts = [{
        type: action.payload.type || 'error',
        content: action.payload.content,
        code: action.payload.code,
        persist: action.payload.persist || false
      }]
    },
    hideGlobalAlert: (state, action) => {
      state.globalAlerts = state.globalAlerts.filter((a) => a.id !== action.payload.id)
    },
    hideGlobalAlerts: (state) => {
      state.globalAlerts = []
    },
  },
})

export const {
  setDisplayPage, showGlobalAlert, hideGlobalAlert, hideGlobalAlerts, setSessionData, updateSessionData, setToken, setAccountId, setQueryParams, setWizardSessionToken, setWizardSessionServiceProvider, setWizardSessionRedirectUrl, setWizardSessionDirectUrl
} = app.actions

export default app.reducer
