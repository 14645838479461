import React from 'react'
import LogoPageWrapper from 'components/ui/logo-page-wrapper'
import { useSelector } from 'react-redux'

const UnlimitProvider = () => {
  const sessionData = useSelector((state) => state.app.sessionData)

  if (sessionData?.sessionData?.paymentMethodType === 'APPLE_PAY') {
    window.open(sessionData?.sessionData?.serviceProviderUrl, '_self')
    return false
  }

  return (
    <LogoPageWrapper>
      <div className="w-[420px] h-[680px] border border-gray-600 rounded-lg overflow-hidden">
        <iframe
          id="unlimit-iframe"
          title="unlimit-iframe"
          allow="accelerometer; autoplay; camera; microphone; gyroscope; payment"
          frameBorder="0"
          src={sessionData.sessionData.serviceProviderUrl}
          style={{ width: '100%', maxWidth: 430, height: '100%', minHeight: 680, background: 'transparent' }}
          className="w-full h-full"
        >
          <p>Your browser does not support iframes.</p>
        </iframe>
      </div>
    </LogoPageWrapper>
  )
}

export default UnlimitProvider
